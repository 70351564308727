/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { navigate } from 'gatsby';
(window as any).gatsbyUtils = {
  navigate
};

export function onClientEntry(){
  window.dispatchEvent(new CustomEvent('gatsby:onClientEntry'));
}

export function onInitialClientRender(){
  window.dispatchEvent(new CustomEvent('gatsby:onInitialClientRender'));
}

export function onRouteUpdate({ location, prevLocation }){
  window.optimizely = window.optimizely || [];
  window.optimizely.push({ type: 'activate' });
  window.dispatchEvent(new CustomEvent('gatsby:onRouteUpdate', {
    detail: { location, prevLocation }
  }));
}